import React from "react"
import ListItem from "./listItem"

const people = [
  {
    name: "Like Nastya Vlog ",
    subs: "28.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78DPAXccWn_Vo9D9FdJ3bXiK8xkhb6MFYKFWg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCJplp5SjeGSdVdwsfb9Q7lQ",
    rank: 1,
  },
  {
    name: "BLACKPINK",
    subs: "27.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-NQdbfxI9mOxPjpTV5NZ34oHC56_TgbLIHAA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCOmHUn--16B90oW2L6FRR3A",
    rank: 2,
  },
  {
    name: "Shakira Mebarak",
    subs: "25.6M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-taVa17chaC0XUOZJ47WWZxLxhANqhvqOuTg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCYLNGLIzMhRTi6ZOLjAPSmw",
    rank: 3,
  },
  {
    name: "XXXTENTACION",
    subs: "20.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l790BB6Trrr2PPU2V-6s8ATMGGC-Leyoe_vCVQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCM9r1xn6s30OnlJWb-jc3Sw",
    rank: 4,
  },
  {
    name: "LosPolinesios",
    subs: "19.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_vJkkMKU8jsCmWugtjs9te0L2H6lrtXLbaMw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCs8qka8tfhdc69wzXYdtZ3A",
    rank: 5,
  },
  {
    name: "Jake Paul",
    subs: "19.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_I4IggeiUyO7q6y-kFIDh9OfZ8RxtUVaYBwg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCcgVECVN4OKV6DH1jLkqmcA",
    rank: 6,
  },
  {
    name: "BuzzFeedVideo",
    subs: "19M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_Kj1-Q94fO0rd6KPp1lo4OnTqQ0tjhjWqQcA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCpko_-a4wgz2u_DgDgd9fqA",
    rank: 7,
  },
  {
    name: "Kimberly Loaiza",
    subs: "18.6M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78jz-i_rVUbuKbpnIMKzZ9IWsW-RyYek07yeQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCQZfFRohQ7UX-0CdXl-6pwQ",
    rank: 8,
  },
  {
    name: "The ACE Family",
    subs: "16.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-XsWCkgkv3EYWecQ_aKwGNFSswLWZKyqQ2zg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCWwWOFsW68TqXE-HZLC3WIA",
    rank: 9,
  },
  {
    name: "Juan De Dios Pantoja",
    subs: "16.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78ZZW8X5RHb8ig-cxeAw7iJ3NORYgUkpSVNLA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCzoUWqjCbcfWFdOMvoep8FA",
    rank: 10,
  },
  {
    name: "SSSniperWolf",
    subs: "15.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78rzP_G-iqu5zytYgVs76rxS5LdcY1NYqbi5w=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/SSSniperWolf",
    rank: 11,
  },
  {
    name: "Roman Atwood Vlogs ",
    subs: "15.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78fJzknEYlOaC2RIBaEHEjEK9VUfKMYNzRZbg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC-SV8-bUJfXjrRMnp7F8Wzw",
    rank: 12,
  },
  {
    name: "Las Ratitas",
    subs: "15.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-bVOJhlH9_NqNhsQ8U3HlrOWs02wmuCstk=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCUY6t_N9MyEWu6I0ZXkbtKA",
    rank: 13,
  },
  {
    name: "Tasty",
    subs: "14.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79wniZfBdNgKPqOAmbEOGvIcEZmxBCOYfsDcw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCJFp8uSYCjXOMnkUyb3CQ3Q",
    rank: 14,
  },
  {
    name: "JukiLop",
    subs: "14.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_J6YBoXdobuni0bjZvQrclVNqguTXq-tc4XA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCHDeuavvkVwDyJgRFVfbpXA",
    rank: 15,
  },
  {
    name: "Lady Gaga",
    subs: "13.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l791mHY13n4kb-Rw9acdJF34SgxV1YrhspXL8w=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCNL1ZadSjHpjm4q9j2sVtOA",
    rank: 16,
  },
  {
    name: "Miss Katy",
    subs: "13.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79MYXIMyjOEfazwkXFbmnNdMisWqlVOuoQn9Q=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCcartHVtvAUzfajflyeT_Gg",
    rank: 17,
  },
  {
    name: "ExtraPolinesios",
    subs: "13.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_tkZfnIjq5ozR8X-ID2vJaed9bk4bwnmgn7Q=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCY8LnVxm0Hf7DKPKuLPxb2g",
    rank: 18,
  },
  {
    name: "Boram Tube ToysReview [보람튜브 토이리뷰]",
    subs: "13.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79c9_OcRKrBmXWiffWo-DRg00_i_Ebe9bGCRQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC3pnQ7MHDABUFungNcMQ7dA",
    rank: 19,
  },
  {
    name: "David Dobrik",
    subs: "12.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79vckvHBr6UeQ7l079sE1lx_sjubqLBr59GgQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCmh5gdwCx6lN7gEC20leNVA",
    rank: 20,
  },
  {
    name: "AM3NlC",
    subs: "12.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-KqZRfszVE4tWtpY0b8OveVTLRZfHeTGMD8A=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCrGnb5RdHA9M6WGmBGYo4ZQ",
    rank: 21,
  },
  {
    name: "mmoshaya",
    subs: "11.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_fdlEoHBBoWOBv-k-j0U1CR5txfU6Zj1riag=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/mmoshaya",
    rank: 22,
  },
  {
    name: "Tu COSMOPOLIS",
    subs: "11.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_obpGyTuzwOv1rxdEkLINztjDs-EvWZuwgBA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCo4ywJQXfUIB0lCBpczW3cA",
    rank: 23,
  },
  {
    name: "CaseyNeistat",
    subs: "11.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-Bym8RoRNkvegjv3WDaodzKSqJQfm7CM1Obg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCtinbF-Q-fVthA0qrFQTgXQ",
    rank: 24,
  },
  {
    name: "Everson Zoio",
    subs: "11M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78ELDh0CMDMk91S-HsVFnAFGCfevrcXgx88Ag=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UChPa3fnlI3RKuzEbeEPKRFw",
    rank: 25,
  },
]

const People = () => <ListItem data={people} />

export default People
