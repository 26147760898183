import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import People from "../components/people"
import SelectRouter from "./selectRouter"

const peoplePage = () => {
  return (
    <Layout>
      <SEO
        title="Top YouTube Gaming Channels"
        description="youtuber.life brings you top youtube gaming channels"
      />
      <SelectRouter />
      <People />
    </Layout>
  )
}
export default peoplePage
